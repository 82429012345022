.planner {
    padding-top: 5vh;
    background-color: rgb(255, 255, 255);
    padding-bottom: 5vh;
}

.box {
    margin: 3rem 2rem;
    padding: 2rem 1rem;
    border-radius: 50px;
    color: white;
    background: linear-gradient(to right, rgb(0, 234, 255) , rgb(0, 140, 255));
    box-shadow: 8px 8px 8px grey;
    transition: box-shadow 0.3s ease-in-out;
    text-align: center;
    font-size: 1.5rem;
}

.box:hover {
    box-shadow: 12px 12px 20px grey;
}

.title {
    text-align: start;
    padding-bottom: 1rem;
}

.items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.item {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0.5rem 0;
}

.label {
    font-size: 1rem;
    margin-bottom: 0.5rem;
}

.input {
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(210, 210, 210);
    text-align: center;
    width: 50%;
    border-radius: 10px;
    padding: 1rem;
    outline: none;
}

.input::placeholder {
    color: rgb(195, 195, 195);
}



@media only screen and (min-width: 750px) {
    .box {
        margin: 3rem 10rem;
        padding: 2rem 1rem;
        border-radius: 50px;
        color: white;
        background: linear-gradient(to right, rgb(0, 234, 255) , rgb(0, 140, 255));
        box-shadow: 8px 8px 8px grey;
        transition: box-shadow 0.3s ease-in-out;
        text-align: center;
        font-size: 1.5rem;
    }
    
}