.box {
  margin: 2rem 2rem;
  padding: 2rem 1rem;
  border-radius: 50px;
  color: white;
  background: linear-gradient(to left, #30cfd0 0%, #330867 100%);
  box-shadow: 8px 8px 8px grey;
  transition: box-shadow 0.3s ease-in-out;
  text-align: center;
  font-size: 1.5rem;
}

.box:hover {
  box-shadow: 12px 12px 20px grey;
}

.title {
  text-align: start;
  padding-left: 1rem;
  padding-bottom: 1rem;
}

.items {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.item {
  display: flex;
  margin: 1rem 0;
  width: 100%;
}

.name {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}


.weight {
  width: 60%;
}

.times {
  width: 50%;
}




@media only screen and (min-width: 750px) {
  .box {
    margin: 2rem 10rem;
    padding: 2rem 1rem;
    border-radius: 50px;
    color: white;
    background: linear-gradient(to left, #30cfd0 0%, #330867 100%);
    box-shadow: 8px 8px 8px grey;
    transition: box-shadow 0.3s ease-in-out;
    text-align: center;
    font-size: 1.5rem;
  }
}