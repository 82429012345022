.box {
  margin: 3rem 2rem;
  padding: 2rem 1rem;
  border-radius: 50px;
  color: rgb(0, 179, 255);
  background: white;
  border: 1px solid rgb(0, 179, 255);
  box-shadow: 8px 8px 8px grey;
  transition: box-shadow 0.3s ease-in-out;
  text-align: center;
  font-size: 1.5rem;
}

.box:hover {
  box-shadow: 12px 12px 20px grey;
}

.title {
  text-align: center;
  border-bottom:  1px solid rgb(0, 179, 255);
  padding-bottom: 0.5rem;
}

.items {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.item {
  display: flex;
  padding: 1rem 0;
}

.date {
  width: 20vw;
}

.amount {
  width: 30vw;
  border-left: 1px solid rgb(0, 179, 255);
  border-right: 1px solid rgb(0, 179, 255);
}

.detail {
  width: 20vw;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.delete {
  background: linear-gradient(to right, rgb(0, 234, 255) , rgb(0, 140, 255));
  border-radius: 10px;
  cursor: pointer;
  background-clip: text;
  color: transparent;
  border: 1px solid rgb(0, 179, 255);
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.delete:hover {
  color: white;
  background-clip: border-box;
}


.select {
  border: 1px solid rgb(0, 179, 255);
  color: rgb(0, 179, 255);
  border-radius: 10px;
  padding: 0.3rem;
}

.select:focus {
  outline: none;
}


@media only screen and (min-width: 750px) {
  .box {
    margin: 3rem 8rem;
    padding: 2rem 1rem;
    border-radius: 50px;
    color: rgb(0, 179, 255);
    background: white;
    border: 1px solid rgb(0, 179, 255);
    box-shadow: 8px 8px 8px grey;
    transition: box-shadow 0.3s ease-in-out;
    text-align: center;
    font-size: 1.5rem;
  }
    
  .date {
    width: 10vw;
  }

  .amount {
    width: 15vw;
    border-left: 1px solid rgb(0, 179, 255);
    border-right: 1px solid rgb(0, 179, 255);
  }

  .detail {
    width: 30vw;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
  }

  .delete {
    background: linear-gradient(to right, rgb(0, 234, 255) , rgb(0, 140, 255));
    border-radius: 10px;
    cursor: pointer;
    background-clip: text;
    color: transparent;
    border: 1px solid rgb(0, 179, 255);
    margin-left: 2rem;
    margin-right: 0.5rem;
  }
}