
.circle {
  width: 80vw;
  height: 80vw;
  margin: 1rem auto;
  border-radius: 50%;
  color: white;
  background: linear-gradient(180deg, #21c57b 0%, #006cb0 100%);
  box-shadow: 8px 8px 8px grey;
  transition: box-shadow 0.3s ease-in-out;
  text-align: center;
  font-size: 1.5rem;
}

.circle:hover {
  box-shadow: 12px 12px 20px grey;
}

.title {
  padding-top: 4rem;
  padding-bottom: 2rem;
  font-size: 2rem;
}

.fields {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 2rem;
}

.field {
  padding-bottom: 1rem;
}

.select {
  border: 1px solid rgb(0, 179, 255);
  color: rgb(0, 179, 255);
  border-radius: 10px;
  padding: 0.3rem;
}

.select:focus {
  outline: none;
}

.input {
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(210, 210, 210);
  text-align: center;
  width: 50%;
  border-radius: 10px;
  padding: 1rem;
  outline: none;
}

.button {
  background-color: white;
  border-radius: 10px;
  display: inline-flex;
  padding: 0.7rem 1.5rem;
  font-size: 1.5rem;
  cursor: pointer;
  color: rgb(0, 179, 255);
  border: 1px solid rgb(0, 179, 255);
}

.button:hover {
  background-color: rgb(0, 179, 255);
  color: white;
}




@media only screen and (min-width: 750px) {
  .circle {
    width: 40vw;
    height: 40vw;
    margin: 1rem auto;
    border-radius: 50%;
    color: white;
    background: linear-gradient(180deg, #21c57b 0%, #006cb0 100%);
    box-shadow: 8px 8px 8px grey;
    transition: box-shadow 0.3s ease-in-out;
    text-align: center;
    font-size: 1.5rem;
  }
}