
.circle {
  width: 80vw;
  height: 80vw;
  margin: 1.5rem auto;
  border-radius: 50%;
  color: white;
  background: linear-gradient(to right, #f83600 0%, #f9d423 100%);
  background-clip: text;
  color: transparent;
  box-shadow: 8px 8px 8px grey;
  transition: box-shadow 0.3s ease-in-out;
  text-align: center;
  font-size: 2rem;
  border: 1.5px solid #f86700;
}

.circle:hover {
  box-shadow: 12px 12px 20px grey;
}

.title {
  padding-top: 5rem;
  padding-bottom: 3rem;
}


@media only screen and (min-width: 750px) {
  .circle {
    width: 40vw;
    height: 40vw;
  }
  
}

