
/*login classes*/
.login {
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: black;
}


.login_container {
  width: 22rem;
  height: 22rem;
  border-radius: 50%;
  margin: auto;
  border: 1.5px solid #26dc5cbd;
  background-color: #050525;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login_form {
  background-color: #84848aaf;
  color: #fff;
  border: 1px solid green;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  padding: 1rem 0.8rem;
}
.login_form::placeholder {
  color: #fff;
}

.login_button {
  background-color: #050525; 
  color: #fff; 
  border: 1.5px solid #007bff; 
  border-radius: 5px; 
  cursor: pointer;
  padding: 0.8rem 2em; 
  margin-top: 2rem;
}
.login_button:hover {
  background-color: #007bff;
}

.update_button {
  background-color: #050525; 
  color: #fff; 
  border: 1.5px solid #ff0022; 
  border-radius: 5px; 
  cursor: pointer;
  padding: 0.8rem 2em; 
  margin-top: 2rem;
}
.update_button:hover {
  background-color: #ff0022;
}

.home_button {
  background-color: #050525; 
  color: #fff; 
  border: 1.5px solid #007bff; 
  border-radius: 5px; 
  cursor: pointer;
  padding: 0.8rem 2em; 
  margin-top: 1rem;
  text-decoration: none;
}
.home_button:hover {
  background-color: #007bff;
}