.header {
    background: linear-gradient(to right, rgb(0, 162, 255, 1) 10%, rgb(0, 34, 137, 1) 50%, black);
    color: white;
    text-align: center;
}

.top {
    display: flex;
    padding-top: 2rem;
}


.title {
    font-size: 3rem;   
    margin: 0 auto;
}

.icon {
    box-sizing: border-box;
    font-size: 2rem;
    cursor: pointer;
    position: absolute;
    left: 90%;
}

.link {
    color: white;
}

.link:hover {
    color: rgb(255, 166, 77);
}


.bottom {
    padding-top: 1rem;
    padding-bottom: 2rem;
}

.field {
    display: inline-block;
    margin: 0 0.8rem;
    font-size: 1.5rem;
    font-family: 'Times New Roman', Times, serif;
    cursor: pointer;
    border-bottom: 1px solid rgba(255, 255, 255, 0);
}

.field:hover {
    color: rgb(255, 166, 77);
    border-bottom: 1px solid rgb(255, 166, 77);
}


@media only screen and (max-width: 430px) {
    .title {
        font-size: 2rem;   
        margin: 0 auto;
    }
}
