.box {
  margin: 2rem 2rem;
  padding: 2rem 1rem;
  border-radius: 50px;
  color: white;
  background: linear-gradient(to left, #30cfd0 0%, #330867 100%);
  box-shadow: 8px 8px 8px grey;
  transition: box-shadow 0.3s ease-in-out;
  text-align: center;
  font-size: 1.5rem;
}

.box:hover {
  box-shadow: 12px 12px 20px grey;
}

.title {
  text-align: start;
  padding-left: 1rem;
  padding-bottom: 1rem;
}

.items {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.item {
  display: flex;
  margin: 1rem 0;
  width: 100%;
}

.name {
  width: 100%;
  padding: 1rem 0.2rem;
  margin: 0 0.2rem;
  outline: none;
  border: none;
  border-radius: 5px;
  color: white;
  background-color: rgb(110, 110, 110);
  text-align: center;
}

.name::placeholder {
  color: white;
}

.weight {
  width: 40%;
  padding: 1rem 0.2rem;
  margin: 0 0.2rem;
  outline: none;
  border: none;
  border-radius: 5px;
  color: white;
  background-color: rgb(110, 110, 110);
  text-align: center;
}

.weight::placeholder {
  color: white;
}

.times {
  width: 40%;
  padding: 1rem 0.2rem;
  margin: 0 0.2rem;
  outline: none;
  border: none;
  border-radius: 5px;
  color: white;
  background-color: rgb(110, 110, 110);
  text-align: center;
}

.times::placeholder {
  color: white;
}

.button {
  background-color: white;
  border-radius: 10px;
  width: 30%;
  font-size: 1rem;
  cursor: pointer;
  color: rgb(110, 110, 110);
  border: 1px solid rgb(110, 110, 110);
  margin: 0 1rem;
}

.button:hover {
  background-color: rgb(110, 110, 110);
  color: white;
}



@media only screen and (min-width: 750px) {
  .box {
    margin: 2rem 10rem;
    padding: 2rem 1rem;
    border-radius: 50px;
    color: white;
    background: linear-gradient(to left, #30cfd0 0%, #330867 100%);
    box-shadow: 8px 8px 8px grey;
    transition: box-shadow 0.3s ease-in-out;
    text-align: center;
    font-size: 1.5rem;
  }
}