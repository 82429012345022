.footer {
  position: fixed;
  bottom: 0;
  height: 8vh; 
  background-color: rgb(70, 70, 70);
  display: flex; 
  justify-content: space-around;
  width: 100%;
  align-items: center;
}

.button {
  color: rgb(191, 191, 191);
  background-color: transparent;
  font-size: 3.5rem;
  cursor: pointer;
}

.button:hover {
  background: linear-gradient(to right, rgb(0, 217, 255) 30%, rgb(0, 145, 255) 60%, rgb(0, 102, 255));
  background-clip: text;
  color: transparent;
  
}

.button_selected {
  background: linear-gradient(to right, rgb(0, 217, 255) 30%, rgb(0, 145, 255) 60%, rgb(0, 102, 255));
  background-clip: text;
  color: transparent;
  font-size: 3.5rem;
  cursor: not-allowed;
}







@media only screen and (min-width: 750px) {
  .button {
    color: rgb(191, 191, 191);
    background-color: transparent;
    font-size: 1.7rem;
    cursor: pointer;
  }
  .button_selected {
    background: linear-gradient(to right, rgb(0, 217, 255) 30%, rgb(0, 145, 255) 60%, rgb(0, 102, 255));
    background-clip: text;
    color: transparent;
    font-size: 1.7rem;
    cursor: not-allowed;
  }
}