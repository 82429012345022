.footer {
  position: fixed;
  bottom: 0;
  height: 8vh; 
  display: flex; 
  justify-content: space-around;
  width: 100%;
  align-items: center;
  border-top: 1px solid rgb(191, 191, 191);
  background-color: white;
}

.button {
  color: rgb(191, 191, 191);
  background-color: transparent;
  font-size: 3.5rem;
  cursor: pointer;
}

.button:hover {
  background: linear-gradient(to right, #f83600 0%, #f9d423 100%);
  background-clip: text;
  color: transparent;
}

.button_selected {
  background: linear-gradient(to right, #f83600 0%, #f9d423 100%);
  background-clip: text;
  color: transparent;
  font-size: 3.5rem;
  cursor: not-allowed;
}






@media only screen and (min-width: 750px) {
  .button {
    font-size: 1.7rem;
  }
  .button_selected {
    font-size: 1.7rem;
  }
}