.header {
  position: fixed;
  top: 0;
  height: 8vh; 
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  border-bottom: 1px solid rgb(191, 191, 191);
  background-color: white;
}

.title {
  background: linear-gradient(to right, #43e97b 0%, #38f9d7 100%);
  background-clip: text;
  color: transparent;
}

.button {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 2rem;
  background: linear-gradient(to right, #43e97b 0%, #38f9d7 100%);background-clip: text;
  color: transparent;
}