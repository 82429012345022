.footer {
    background: linear-gradient(to right, rgb(0, 162, 255, 1) 10%, rgb(0, 34, 137, 1) 50%, black);
    color: white;
    text-align: center;
}

.sns {
    display: flex;
    justify-content: center;
    padding-top: 2rem;
}

.icon {
    font-size: 2.5rem;
    margin: 0 0.5rem;
    cursor: pointer;
}

.link {
    color: white;
}

.link:hover {
    color: rgb(255, 166, 77);
}

.copyright {
    color: rgb(156, 156, 156);
    font-size: 0.8rem;
    padding: 1rem 0;
}