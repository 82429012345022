.header {
    position: fixed;
    top: 0;
    height: 8vh; 
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    background-color: white;
    border-bottom: 1px solid rgb(191, 191, 191);
}

.title {
    background: linear-gradient(to right, rgb(0, 217, 255) 30%, rgb(0, 145, 255) 60%, rgb(0, 102, 255));
    background-clip: text;
    color: transparent;
}

.button {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 2rem;
    background: linear-gradient(to right, rgb(0, 217, 255) 30%, rgb(0, 145, 255) 60%, rgb(0, 102, 255));
    background-clip: text;
    color: transparent;
  }