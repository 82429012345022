.contents {
    text-align: center;
    padding: 0 8rem;
    padding-bottom: 4rem;
}

.title {
    font-size: 4rem;
    padding-top: 5rem;
    padding-bottom: 5rem;
    font-family: 'Times New Roman', Times, serif;
}

.items {
    display: flex;
}

.item {
    margin: 0 3rem;
    width: 20vw;
    height: 20vw;
}

.image {
    display: block;
    width: 100%;
    height: 70%;
    object-fit: cover;
    box-shadow: 8px 8px 8px grey;
    transition: box-shadow 0.3s ease-in-out;
    border-radius: 10%;
}

.image:hover {
    box-shadow: 12px 12px 20px grey;
}

@media only screen and (max-width: 430px) {
    
    .title {
        font-size: 2.6rem;
        padding-top: 4rem;
        padding-bottom: 1rem;
        font-family: 'Times New Roman', Times, serif;
    }
    .contents {
        text-align: center;
        padding: 0 2rem;
        padding-bottom: 4rem;
    }
    .items {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .item {
        margin: 2rem 0rem;
        width: 100%;
        height: 30vh;
    }
    .image {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        box-shadow: 8px 8px 8px grey;
        transition: box-shadow 0.3s ease-in-out;
        border-radius: 10%;
    }
}

@media only screen and (max-width: 850px) and (min-width: 430px) {   
    .item {
        margin: 0.5rem 1rem;
        width: 20vw;
    }
}
