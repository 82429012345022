.buttonbox {
  margin: 2rem 0;
  display: flex;
  justify-content: center;
}

.button {
  border-radius: 10px;
  padding: 0.7rem 2.2rem;
  font-size: 2rem;
  outline: none;
  cursor: pointer;
  background: white;
  color: #f86700;
  border: 1px solid #f86700;
}

.button:hover {
  background: linear-gradient(to right, #f83600 0%, #f9d423 100%);
  color: white;
  border: 1px solid #f8be00;
}

.spinnerbox {
  height: 2rem;
  margin: auto 0;
}

.spinner {
  margin-left: 0.5rem;
}

