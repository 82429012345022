
/*login classes*/
.logout {
  display: flex;
  justify-content: center;
}



.logout_button {
  background-color: #ffffff; 
  color: #000000; 
  border: 1.5px solid #007bff; 
  border-radius: 5px; 
  cursor: pointer;
  padding: 0.8rem 2em; 
  margin: 1rem 0;
  margin-right: 1rem;
}
.logout_button:hover {
  background-color: #007bff;
  color: white;
}

.update_button {
  background-color: #ffffff; 
  color: #000000; 
  border: 1.5px solid #ff0022; 
  border-radius: 5px; 
  cursor: pointer;
  padding: 0.8rem 2em;
  margin: 1rem 0;
  margin-left:  1rem; 
  text-decoration: none;
}
.update_button:hover {
  background-color: #ff0022;
  color: white;
}