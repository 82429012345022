.savebutton {
    margin-bottom: 3rem;
    display: flex;
    justify-content: center;
}

.button {
    background: linear-gradient(to right, rgb(0, 234, 255) , rgb(0, 140, 255));
    border-radius: 10px;
    display: inline-flex;
    padding: 0.7rem 2.2rem;
    font-size: 2rem;
    cursor: pointer;
    background-clip: text;
    color: transparent;
    border: 1px solid rgb(0, 179, 255);
}

.button:hover {
    color: white;
    background-clip: border-box;
}

.spinnerbox {
    height: 2rem;
    margin: auto 0;
}

.spinner {
    margin-left: 0.5rem;
}

