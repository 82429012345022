.buttonbox {
  margin: 2rem 0;
  display: flex;
  justify-content: center;
}

.button {
  border-radius: 10px;
  padding: 0.7rem 2.2rem;
  font-size: 2rem;
  outline: none;
  cursor: pointer;
  background: white;
  color: rgb(0, 179, 255);
  border: 1px solid rgb(0, 179, 255);
}

.button:hover {
  background: linear-gradient(to left, #30cfd0 0%, #330867 100%);
  color: white;
  border: 1px solid #330867;
}

.spinnerbox {
  height: 2rem;
  margin: auto 0;
}

.spinner {
  margin-left: 0.5rem;
}

