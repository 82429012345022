.form {
    padding: 3rem 10rem;
    background-color: #e7e7e7e5;
}

.title {
    text-align: center;
    color: black;
    font-size: 4rem;
    padding-top: 1.5rem;
    padding-bottom: 3rem;
    font-family: 'Times New Roman', Times, serif;
}

.yourname {
    width: 100%;
    border-radius: 10px;
    border: 1.5px solid rgb(174, 174, 174);
    padding: 1rem 1rem;
    box-sizing: border-box;
    font-size: 1.2rem;
    outline: none;
    margin-bottom: 0.5rem;
}
.mail {
    width: 100%;
    border-radius: 10px;
    border: 1.5px solid rgb(174, 174, 174);
    padding: 1rem 1rem;
    box-sizing: border-box;
    font-size: 1.2rem;
    outline: none;
}
.phone{
    width: 100%;
    border-radius: 10px;
    border: 1.5px solid rgb(174, 174, 174);
    padding: 1rem 1rem;
    box-sizing: border-box;
    margin-top: 1rem;
    font-size: 1.2rem;
    outline: none;
}
.comment {
    width: 100%;
    border-radius: 10px;
    border: 1.5px solid rgb(174, 174, 174);
    padding: 1rem;
    padding-bottom: 2rem;
    box-sizing: border-box;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-family: inherit;
    font-size: 1.2rem;
    outline: none;
}

.buttonload {
    display: flex;
    align-items: center;
}

.button {
    background: linear-gradient(to right, rgb(0, 162, 255, 1) , rgb(0, 34, 137, 1) , black);
    color: white;
    border-radius: 10px;
    display: inline-flex;
    padding: 0.7rem 2.2rem;
    font-size: 1.2rem;
    cursor: pointer;
    border: none;
}


.button:hover {
    background: linear-gradient(to right, rgb(255, 153, 0) , rgb(255, 0, 0));
}

.spinnerbox {
    height: 2rem;
}

.spinner {
    margin-left: 0.5rem;
}


@media only screen and (max-width: 430px) {
    
    .title {
        text-align: center;
        color: black;
        font-size: 2.5rem;
        padding-top: 1.5rem;
        padding-bottom: 3rem;
        font-family: 'Times New Roman', Times, serif;
    }
    .form {
        padding: 3rem 1rem;
        background-color: #e7e7e7e5;
    }
    
}