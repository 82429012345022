
.circle {
    width: 80vw;
    height: 80vw;
    margin: 3rem auto;
    border-radius: 50%;
    color: white;
    background: linear-gradient(to right, rgb(0, 234, 255) , rgb(0, 140, 255));
    box-shadow: 8px 8px 8px grey;
    transition: box-shadow 0.3s ease-in-out;
    text-align: center;
    font-size: 1.5rem;
    border: 1px solid rgb(0, 179, 255);
}

.circle:hover {
    box-shadow: 12px 12px 20px grey;
}

.title {
    padding-top: 4rem;
    padding-bottom: 2rem;
    font-size: 2rem;
}

.fields {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 2rem;
}

.field {
    padding-bottom: 1rem;
}

.input {
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(210, 210, 210);
    text-align: center;
    width: 50%;
    border-radius: 10px;
    padding: 1rem;
    outline: none;
}


@media only screen and (min-width: 750px) {
    .circle {
        width: 40vw;
        height: 40vw;
        margin: 3rem auto;
        border-radius: 50%;
        color: white;
        background: linear-gradient(to right, rgb(0, 234, 255) , rgb(0, 140, 255));
        box-shadow: 8px 8px 8px grey;
        transition: box-shadow 0.3s ease-in-out;
        text-align: center;
        font-size: 1.5rem;
        border: 1px solid rgb(0, 179, 255);
    }
}